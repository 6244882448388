<template>
  <div class="notFound">

      <div class="container">

      <div style="padding: 20px;">
        <h1 style="font-size: 100px"><strong>404</strong></h1>
        <hr>
        <h3>Page Not Found</h3>
      </div>

      <div style="padding: 20px;">
        <img width="30%" src="./../../public/LogoBgnd.png" alt="">
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'notFound'
}
</script>

<style scoped>

.notFound {
  padding-top: 140px;
  padding-bottom: 40px;
}

  
</style>
